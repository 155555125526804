import React from "react"
import Layout from "../components/Layout/layout"
import Designer from "../components/Designer"
import { Apps } from "@material-ui/icons"

// This is set up so the Designer app can be mixed and matched with other design or create Apps.
const Create = () => {
  return (
    <Layout>
      <Designer />
    </Layout>
  )
}

export default Create
