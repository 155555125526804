import React, { useState } from "react"
import { Container, Drawer, Grid } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import Header from "./Header"
// import Stager from "./Stager"
import Options from "./Options"

const Designer = ({ classes }) => {
  const [expanded, setExpanded] = useState(false)
  const { hiddenDrawer, setHiddenDrawer } = useState(false)
  const isBrowser = () => typeof window !== "undefined"
  return (
    <div className={classes.root}>
      {/* <Container maxWidth="lg"> */}
      <Header />
      <Grid container spacing={1}>
        <>
          <Grid item xxs={expanded ? 12 : 2} sm={expanded ? 12 : 4} lg={3}>
            <Options expanded={expanded} />
          </Grid>
          <Grid item xxs={12} sm={8} lg={9}>
            <div></div>
            {/* {isBrowser ? <Stager /> : <div></div>} */}
          </Grid>
        </>
      </Grid>
      {/* </Container> */}
    </div>
  )
}
const styles = theme => ({
  root: {
    minWidth: 225,
    flexGrow: 1,
    margin: theme.spacing(0, 0.5, 0.5, 0.5),

    // margin: theme.spacing(1, 0, 0),
  },
  container: {
    display: "grid",
  },
  itemImage: {
    height: 420,
  },
})

export default withStyles(styles)(Designer)
