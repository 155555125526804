import React from "react"
import {
  Grid,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import InboxIcon from "@material-ui/icons/MoveToInbox"
import MailIcon from "@material-ui/icons/Mail"

const Options = ({ classes, expanded }) => {
  return (
    <div>
      <List>
        {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            {expanded && <ListItemText primary={text} />}
          </ListItem>
        ))}
      </List>
    </div>
  )
}
const styles = theme => ({
  root: {
    minWidth: 225,
    flexGrow: 1,
    margin: theme.spacing(2, 0.5, 0.5, 0.5),
  },
  title: {
    flexGrow: 1,
  },
})

export default withStyles(styles)(Options)
