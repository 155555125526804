import React from "react"
import { Grid, Toolbar, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"

const Header = ({ classes }) => {
  return (
    <div className={classes.root}>
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          Designer
        </Typography>
      </Toolbar>
    </div>
  )
}

const styles = theme => ({
  root: {
    minWidth: 225,
    flexGrow: 1,
    margin: theme.spacing(2, 0.5, 0.5, 0.5),
  },
  title: {
    flexGrow: 1,
  },
})
export default withStyles(styles)(Header)
